import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import {CurrencyKind, CurrencyType, ICurrency} from '@type/currency'
import {fetchCurrencies} from '@api/core/currency/currency'
import {equalString} from '@util/strings'

const DEFAULT_SYMBOL = CurrencyKind.USD
interface ICurrencyStore {
    selectedSymbol: CurrencyType
    currencies: ICurrency[]
    fetchCurrencies: () => void
    changeCurrency: (symbol: CurrencyType) => void
    getSelectedCurrency: () => ICurrency
    getUSDCurrency: () => ICurrency
    getCurrency: (symbol: string) => ICurrency
}

export const useCurrencyStore = create<ICurrencyStore>()(
    persist(
        (set, get) => ({
            selectedSymbol: DEFAULT_SYMBOL,
            currencies: [],
            fetchCurrencies: async () => {
                const {data} = await fetchCurrencies()
                set(state => ({currencies: data}))
            },
            getSelectedCurrency: () => {
                return get()?.currencies?.find(item => equalString(item.symbol, get().selectedSymbol))
            },
            getUSDCurrency: () => {
                return get()?.currencies?.find(item => equalString(item.symbol, DEFAULT_SYMBOL))
            },
            getCurrency: (symbol: string) => {
                return get()?.currencies?.find(item => equalString(item.symbol, symbol))
            },
            changeCurrency: (symbol: CurrencyType = DEFAULT_SYMBOL) => {
                set(state => ({selectedSymbol: symbol}))
            },
        }),
        {
            name: 'currency-storage',
        },
    ),
)
