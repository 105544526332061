export const CurrencyKind = {
    USD: 'USD',
    KRW: 'KRW',
    CNY: 'CNY',
    EUR: 'EUR',
    JPY: 'JPY',
    BTC: 'BTC',
} as const
export type CurrencyType = string | (typeof CurrencyKind)[keyof typeof CurrencyKind]

export interface ICurrency {
    symbol: CurrencyType
    value: number
    is_stable: boolean
    name: string
    unicode: string
    is_fiat?: boolean
    price_decimals?: {
        small?: number
        default?: number
    }
    balance_decimals?: {
        small?: number
        default?: number
    }
    circle_decimals?: {
        small?: number
        default?: number
    }
}
