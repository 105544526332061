export const addImageWebpQuery = (source?: any, width?: number, height?: number, isResize: boolean = true) => {
    if (!source) return

    if (typeof source === 'string') {
        if (source?.includes('cache.leagueoftraders.io')) {
            const url2 = new URL(source)
            if (isResize) {
                url2.searchParams.append('type', 'webp')
                url2.searchParams.append('width', `${Number(width) < 200 ? 200 : width}`)
                url2.searchParams.append('quality', '75')
            } else {
                url2.searchParams.append('type', 'webp')
                url2.searchParams.append('quality', '75')
                url2.searchParams.append('action', 'fit')
            }

            return url2.toString()
        } else return source
    } else {
        return source
    }
}
