import React, {useEffect} from 'react'
import {useCurrencyStore} from '@store/CurrencyStore'
import useQueryGetPoint from '@hook/query/account/point/useQueryGetPoint'
import {CurrencyKind} from '@type/currency'

const PresetLoader = () => {
    const {fetchCurrencies, currencies, selectedSymbol, changeCurrency} = useCurrencyStore(state => state)

    useEffect(() => {
        fetchCurrencies()
    }, [fetchCurrencies])

    useEffect(() => {
        if (currencies.some(currency => currency.symbol?.toLowerCase() === selectedSymbol)) {
            changeCurrency(selectedSymbol.toUpperCase())
        }
    }, [selectedSymbol, currencies])

    return <></>
}

export default PresetLoader
